import React from "react";
import { Container } from "~/components/ui/container";
import type { MetaFunction } from "@remix-run/node";
import { useLoaderData } from "@remix-run/react";
import type { Post, Author } from "~/components/blog/blog-card";
import type { LoaderData, loader } from "./blog-category.server";
import { useSearchParams } from "react-router-dom";
import { CategoryTag } from "~/components/blog/category-tag";
import { IconLink } from "~/components/ui/icon-link";
import { Icon } from "~/components/ui/icons";
import { Spacer } from "~/components/ui/spacer";
import { BlogGrid } from "~/components/blog/blog-grid";
import { Newsletter } from "~/components/newsletter";
import {
	getImageBuilder,
	getImageProps,
	getImageAltProp,
} from "~/utils/images";
import { intersection } from "lodash";
import { H2, H3, Paragraph } from "~/components/ui/typography";
import { BreadCrumbs, Crumb } from "~/components/ui/breadcrumbs";
import { asText } from "~/utils/sanity-helpers";
import { GenericErrorBoundary } from "~/components/errors";
import {
	reuseSeoMeta,
	reuseHeaders,
	getUrl,
	getOrigin,
	capitalize,
} from "~/utils/misc";
import { useSharedContent } from "~/hooks/localization";
import { getSocialMetas } from "~/utils/seo";
import type { loader as rootLoader } from "~/root";
import { spTrackSearchTerm, spTrackWebInteraction } from "~/utils/tracking";
import { useUpdateQueryStringValueWithoutNavigation } from "~/utils/hooks";
import { HoneyPotInput } from "~/components/honeypot-input";
import type { PostPreview } from "~/types/post";
import { getCollectionPageSchema } from "~/utils/schemas";

const TAG_TO_SHOWS = [
	"All articles",
	"Announcements",
	"Product updates",
	"Customer story",
	"Events",
	"Life at Aiven",
];

export const headers = reuseHeaders;

export const meta: MetaFunction<typeof loader, { root: typeof rootLoader }> = (
	metaArgs
) => {
	const { data, matches } = metaArgs;

	const requestInfo = matches.find((m) => m.id === "root")?.data.requestInfo;

	if (!data) {
		return reuseSeoMeta(metaArgs);
	}

	const { category, pageData, posts } = data;

	const origin = getOrigin(requestInfo);
	const itemsList = posts.map((item: PostPreview) => {
		return {
			name: item.title,
			url: `${origin}${item.slug.current}`,
			datePublished: item.publishedAt,
			author: item.authors?.length ? item.authors : [],
			description: item.subtitle,
		};
	});

	const imageBuilder = getImageBuilder(pageData.data.seo?.metaImage, {
		alt: pageData.data.seo?.metaImage?.alt,
	});

	return [
		getCollectionPageSchema({
			pageData: pageData.data,
			baseUrl: getUrl(requestInfo),
			itemsList,
			title: category ? `Aiven Blog | ${category.title}` : "Aiven Blog",
			description: category
				? asText(category.description)
				: asText(pageData.data.seo.metaDescription),
		}),
		...getSocialMetas({
			origin,
			url: getUrl(requestInfo),
			title: category ? `Aiven Blog | ${asText(category.title)}` : "Aiven Blog",
			description: category
				? asText(category.description)
				: asText(pageData.data.seo.metaDescription),
			locale: pageData.data.__i18n_lang,
			image: getImageProps(imageBuilder?.width(1200).height(630)),
		}),
	];
};

type BlogSortOption = "recent" | "relevant";

const SORT_OPTIONS: { value: BlogSortOption; label: string }[] = [
	{ value: "recent", label: "Most recent" },
	{ value: "relevant", label: "Most relevant" },
];

function comparePublishedAt(a: Post, b: Post): number {
	if (a.date && b.date) {
		if (new Date(a.date) < new Date(b.date)) {
			return 1;
		}
		if (new Date(a.date) > new Date(b.date)) {
			return -1;
		}
	}
	return 0;
}

function mapToPost(item: PostPreview): Post {
	const authors: Author[] = item.authors
		? item.authors.map((author) => ({
				name: asText(author?.name),
				shortName: asText(author?.shortName),
				imageBuilder: getImageBuilder(author?.image, {
					alt: getImageAltProp(item.mainImage, {
						fallback: asText(author?.name),
					}),
				}),
			}))
		: [];

	return {
		id: item._id,
		title: asText(item.title),
		date: item.publishedAt,
		coverImgBuilder: getImageBuilder(item.mainImage, {
			alt: getImageAltProp(item.mainImage, {
				fallback: `${asText(item.title)} illustration`,
			}),
		}),
		slug: item.slug.current,
		description: item.subtitle,
		authors,
		categories: item.categories,
	};
}

function BlogHome() {
	const { t } = useSharedContent();
	const { posts, lang, category, recommendationPosts } =
		useLoaderData<LoaderData>();
	const [honeyPot, setHoneyPot] = React.useState("");

	const [searchParams, setSearchParams] = useSearchParams();
	const [queryValue, setQuery] = React.useState<string>(() => {
		return searchParams.get("search") ?? "";
	});

	const sortSearchParams = searchParams.get("sort");
	const [sort, setSort] = React.useState<BlogSortOption>(
		sortSearchParams ? (sortSearchParams as BlogSortOption) : "recent"
	);

	const categoriesSearchParams = searchParams.get("categories");

	const [selectedCategories, setSelectedCategories] = React.useState(
		() =>
			categoriesSearchParams?.split(", ").map((c) => decodeURIComponent(c)) ??
			[]
	);

	const query = queryValue.trim();
	const isSearching = React.useMemo(
		() => Boolean(query) || selectedCategories.length > 0,
		[query, selectedCategories]
	);

	const isSearchingQuery = React.useMemo(() => Boolean(query), [query]);

	useUpdateQueryStringValueWithoutNavigation("sort", sort);

	function handleSortChange(value: string) {
		setSort(value as BlogSortOption);

		spTrackWebInteraction({
			object: "sort",
			action: "order",
			value: value,
		});
	}

	function sortSearchResults() {
		return (
			<div className="relative">
				<select
					className="border-stroke m-0 w-full appearance-none rounded border bg-transparent px-5 py-4 text-sm font-medium lg:w-[160px]"
					onChange={(e) => handleSortChange(e.target.value)}
					value={sort}
				>
					{SORT_OPTIONS.map((item) => (
						<option key={item.value} value={item.value} className="capitalize">
							{capitalize(item.label)}
						</option>
					))}
				</select>
				<Icon
					name="chevron-down"
					width="16"
					height="16"
					color="arrow"
					className="pointer-events-none absolute right-5 top-[20px] z-[1] h-[10px] w-[10px]"
				/>
			</div>
		);
	}

	const matchingPosts = React.useMemo(() => {
		if (!posts) return [];

		if (selectedCategories.length > 0) {
			return posts.filter((post) => {
				const categoryTitles = post.categories
					? post.categories.map((category) => category.title || "")
					: [];

				return (
					intersection(categoryTitles, selectedCategories).length ===
					selectedCategories.length
				);
			});
		}

		return posts;
	}, [selectedCategories, posts]);

	const PAGE_SIZE = category ? 9 : 3;
	const initialIndexToShow = PAGE_SIZE;

	const [indexToShow, setIndexToShow] = React.useState(initialIndexToShow);

	const selectedCategoriesParam = selectedCategories
		.map((c) => encodeURIComponent(c))
		.sort()
		.join(", ");

	useUpdateQueryStringValueWithoutNavigation(
		"categories",
		selectedCategoriesParam
	);

	// when the query changes, we want to reset the index
	React.useEffect(() => {
		setIndexToShow(initialIndexToShow);
	}, [query, initialIndexToShow]);

	// update search params
	React.useEffect(() => {
		if (!query) {
			searchParams.delete("search");
			setSearchParams(searchParams);
		} else {
			searchParams.set("search", query);
			setSearchParams(searchParams);
		}
	}, [queryValue, query, searchParams, setSearchParams]);

	function handleLoadMore(index: number) {
		setIndexToShow(index);
	}

	function renderSection({
		title,
		variant,
		link,
		posts,
	}: {
		title?: string;
		variant?: "default" | "emphasized";
		link?: string;
		posts: Post[];
	}) {
		if (posts.length === 0) {
			return null;
		}
		return (
			<section>
				{title ? (
					<div className="mb-6 flex items-center justify-between">
						{title ? <H3 as="h2">{title}</H3> : null}
						{link ? (
							<IconLink
								to={link}
								iconRight={<Icon name="arrow-right" color="primary" />}
								className="hidden lg:flex"
							>
								View all
							</IconLink>
						) : null}
					</div>
				) : null}
				<BlogGrid
					indexToShow={indexToShow}
					onLoadMore={handleLoadMore}
					posts={posts}
					lang={lang}
					variant={variant}
					loadMoreText={t("loadMore")}
				/>
				<Spacer size="layout6" />
			</section>
		);
	}

	function filterPostsByCategory(posts: Post[], category: string): Post[] {
		if (!posts) return [];

		return posts
			.filter((post) =>
				post.categories?.some((cat) => cat.title && cat.title === category)
			)
			.slice(0, 3);
	}

	function renderPosts() {
		const posts = matchingPosts.map(mapToPost);

		const recommendedPosts = recommendationPosts?.map(mapToPost) || [];

		if (isSearching && posts.length === 0) {
			return (
				<>
					<Paragraph
						className="m-auto mb-7 max-w-content p-3 text-center font-medium"
						size="body-large"
					>
						Hmm, we didn't find anything for {query}. Here are some of our
						latest posts, maybe you can enjoy those instead?
					</Paragraph>
					{recommendationPosts ? (
						<BlogGrid posts={recommendedPosts} lang={lang} />
					) : null}
					<Spacer size="layout6" />
				</>
			);
		}

		if (posts.length === 0) {
			return (
				<>
					<Paragraph
						className="m-auto mb-7 max-w-content p-3 text-center font-medium"
						size="body-large"
					>
						No blog post available
					</Paragraph>
					<Spacer size="layout6" />
				</>
			);
		}

		const sortedPosts =
			isSearching && sort === "recent"
				? posts.map((x) => x).sort(comparePublishedAt)
				: posts;

		return (
			<>
				{isSearching || category?.title ? (
					<>
						<BlogGrid
							indexToShow={indexToShow}
							onLoadMore={handleLoadMore}
							posts={sortedPosts}
							lang={lang}
							variant={isSearching ? "default" : "emphasized"}
							loadMoreText={t("loadMore")}
						/>
						<Spacer size="layout6" />
					</>
				) : (
					<>
						{renderSection({
							variant: "emphasized",
							posts: posts.slice(0, 3),
						})}
						{renderSection({
							title: "Announcements",
							link: "/blog/category/announcements",
							posts: filterPostsByCategory(posts, "Announcements"),
						})}
						{renderSection({
							title: "Product updates",
							link: "/blog/category/product-updates",
							posts: filterPostsByCategory(posts, "Product updates"),
						})}
						<Newsletter />
						<Spacer size="layout6" />
						{renderSection({
							title: "Customer stories",
							link: "/blog/category/customerstory",
							posts: filterPostsByCategory(posts, "Customer story"),
						})}
						{renderSection({
							title: "Events",
							link: "/blog/category/events",
							posts: filterPostsByCategory(posts, "Events"),
						})}
						{renderSection({
							title: "Life at Aiven",
							link: "/blog/category/life-at-aiven",
							posts: filterPostsByCategory(posts, "Life at Aiven"),
						})}
						<section>
							<H3 as="h2" className="mb-6">
								Latest articles
							</H3>
							<BlogGrid
								indexToShow={indexToShow}
								onLoadMore={handleLoadMore}
								posts={sortedPosts.slice(3)}
								lang={lang}
								variant="default"
								loadMoreText={t("loadMore")}
							/>
							<Spacer size="layout6" />
						</section>
					</>
				)}
			</>
		);
	}

	function onSearchChange(event: React.FormEvent<HTMLInputElement>) {
		const query = event.currentTarget.value.toLowerCase();

		if (honeyPot) {
			console.info(`FAILED HONEYPOT WHEN SEARCHING: `, query);
			return;
		}

		setQuery(query);
	}

	function onSearchEnter(event: React.KeyboardEvent<HTMLInputElement>) {
		if (event.key === "Enter") {
			spTrackSearchTerm(event.currentTarget.value.toLowerCase());
		}
	}

	function toggleTag(tag: string) {
		if (tag === "All articles") {
			setSelectedCategories([]);
			setIndexToShow(3);
		} else {
			setSelectedCategories([tag]);
			setIndexToShow(9);
		}

		spTrackWebInteraction({
			object: "tag",
			action: "filter",
			value: tag,
		});
	}

	function onHoneyPotChange(event: React.FormEvent<HTMLInputElement>) {
		const inputTarget = event.currentTarget;
		const value = inputTarget.value;

		setHoneyPot(value);
	}

	function renderSearchInput() {
		const captureSearchTerm = (queryValue: string) => {
			spTrackSearchTerm(queryValue);
		};

		return (
			<div>
				<div className="flex flex-col gap-5 md:flex-row">
					<div className="relative flex-1">
						<Icon
							name="search"
							color="secondary"
							className="absolute left-5 top-1/2 translate-y-[-50%]"
							width="16"
							height="16"
						/>
						<input
							type="search"
							name="search"
							value={queryValue}
							placeholder="I want to read about"
							onChange={onSearchChange}
							onKeyDown={onSearchEnter}
							onBlur={() => queryValue && captureSearchTerm(queryValue)}
							className="border-stroke bg-primary w-full rounded border border-solid p-5 pl-[56px] text-base outline-none"
						/>
						<HoneyPotInput
							value={honeyPot}
							name="language__option"
							onChange={onHoneyPotChange}
						/>
					</div>
				</div>
			</div>
		);
	}

	function renderCategories() {
		return (
			<>
				{/* <Paragraph className="pb-3" size="overline" color="tagline">{t("filterBy")}</Paragraph> */}
				<div className="flex flex-wrap gap-4">
					{TAG_TO_SHOWS.map((tag) => {
						const selected =
							tag === "All articles"
								? selectedCategories.length === 0
								: selectedCategories.includes(tag);

						return (
							<CategoryTag
								key={tag}
								tag={tag}
								selected={selected}
								onClick={() => toggleTag(tag)}
							/>
						);
					})}
				</div>
			</>
		);
	}

	return (
		<Container compactPadding>
			<header>
				{category ? (
					<BreadCrumbs className="mb-layout1 sm:mb-layout2">
						<Crumb to="/blog">Aiven Blog</Crumb>
						<Crumb isCurrentPage={true}>{category?.title}</Crumb>
					</BreadCrumbs>
				) : (
					<Spacer size="layout2" />
				)}
				<H2 as="h1">
					{category ? `${category.title} articles` : "Aiven Blog"}
				</H2>
			</header>
			<Spacer size="layout3" />
			<div role="search" className="flex flex-col gap-5">
				<div className="grow basis-1/3">{renderSearchInput()}</div>
				{!category && matchingPosts.length > 0 ? (
					<div className="basis-2/3">{renderCategories()}</div>
				) : null}
			</div>
			<main>
				{isSearchingQuery && matchingPosts.length > 0 ? (
					<div className="mt-7 flex flex-col gap-3 md:flex-row">
						<Paragraph
							className="flex-1"
							size="body-small"
							fontWeight="font-medium"
						>
							Results: {matchingPosts.length}
						</Paragraph>
						{sortSearchResults()}
					</div>
				) : null}
				<Spacer size="layout4" />
				{renderPosts()}
			</main>
		</Container>
	);
}

export default BlogHome;

export function ErrorBoundary() {
	return <GenericErrorBoundary />;
}
